'use client';

import cn from 'mxcn';
import { useMask } from '@react-input/mask';
import mergeRefs from 'merge-refs';
import React from 'react';
import { mapValues } from 'lodash';
import { useFieldMaybe } from './field/use-field';
import { cva, VariantProps } from 'class-variance-authority';
import useIsHydrated from '@/lib/hooks/use-is-hydrated';
type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & VariantProps<typeof inputVariants> & {
  hint?: string;
  onValueChange?: (value: string) => void;
  mask?: string;
  maskReplacement?: Record<string, RegExp | string>;
  ref?: React.Ref<HTMLInputElement>;
};
export const inputVariants = cva(['relative z-10 flex w-full', 'font-sans', 'rounded', 'transition-colors', 'file:border-0 file:text-sm file:font-medium', 'placeholder:text-contrast-400', 'focus-visible:outline-none focus-visible:ring-2 ring-focus-500/30 focus-visible:border-focus-500'].join(' '), {
  variants: {
    variant: {
      default: 'border border-contrast-200 bg-contrast-0 shadow-sm',
      fill: 'bg-contrast-100',
      ghost: 'bg-transparent focus-visible:ring-0 focus-visible:bg-contrast-900/5'
    },
    size: {
      sm: 'h-7 text-xs px-2 py-0.5',
      md: 'h-9 text-sm px-3 py-1',
      lg: 'h-12 text-lg px-4 py-1.5'
    },
    disabled: {
      true: 'disabled:cursor-not-allowed disabled:opacity-50'
    }
  },
  defaultVariants: {
    size: 'md',
    variant: 'default',
    disabled: false
  }
});
function Input({
  className,
  type,
  size = 'md',
  variant = 'default',
  hint,
  mask,
  maskReplacement,
  ref,
  onValueChange,
  onBlur,
  ...props
}: InputProps) {
  const inputRef = useMask({
    mask,
    replacement: mapValues(maskReplacement, value => typeof value === 'string' ? new RegExp(value) : value)
  });
  ref = mask ? mergeRefs(ref, inputRef) : ref;
  const field = useFieldMaybe<string>();
  if (field && !('value' in props)) {
    props.value = field.value;
    props.id ??= field.id;
    props.name ??= field.name;
    const originalOnValueChange = onValueChange;
    onValueChange = value => {
      field.onValueChange(value);
      originalOnValueChange?.(value);
    };
    const originalOnBlur = onBlur;
    onBlur = e => {
      field.onBlur();
      originalOnBlur?.(e);
    };
  }
  props.value ??= '';
  const isHydrated = useIsHydrated();
  const disabled = props.disabled || !isHydrated;
  return <>
      <div className="stack flex-auto w-full relative">
        <input type={type} className={cn(inputVariants({
        size,
        variant,
        disabled: props.disabled
      }), className)} ref={ref} onInput={e => {
        onValueChange?.((e.target as HTMLInputElement).value);
      }} onBlur={e => {
        onBlur?.(e);
      }} aria-describedby={field && (field.localErrors.length > 0 || field.serverErrors.length > 0) ? field?.id + '-errors' : undefined} {...props} disabled={disabled} />
        {hint && <div className="text-muted text-xs mt-1">{hint}</div>}
      </div>
    </>;
}
export { Input };