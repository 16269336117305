'use client';

import { Atom, useAtomValue } from 'jotai';
import { localFieldStateAtomAtom } from './field';
import { useFieldApi } from './field-api';
import { FieldStateAtom } from './create-field-state';

/**
 * A hook to get the field state and the api for this field.
 */

export function useField<T>() {
  const field = useFieldMaybe<T>();
  if (!field) {
    throw new Error('You tried to useField outside of a <Field>');
  }
  return field;
}
export function useFieldMaybe<T>() {
  const fieldStateAtom = useAtomValue(localFieldStateAtomAtom);
  const api = useFieldApi<T>(fieldStateAtom as FieldStateAtom<T> | Atom<null>);
  return api;
}