import { atom, useAtomValue } from 'jotai';
import { set as setProperty } from 'lodash';
import { FieldStateAtom } from '../field/create-field-state';
import { hydratedAtom } from '@/lib/atoms';
import { ActionError, ActionResult } from '@/lib/action';
import { ZodSchema } from 'zod';

/**
 * A symbol used to indicate that the form has not been submitted yet.
 */
export const UNSUBMITTED = Symbol.for('form.unsubmitted');

// --- Form state ---

/**
 * An atom-of-atoms holding the current, in-memory state atoms of all fields in
 * the form, keyed by field ID.
 */
export const fieldStateAtomsAtom =
  hydratedAtom<Record<string, FieldStateAtom<unknown>>>('fieldStateAtoms');
export const formIsSubmittingAtom = hydratedAtom<boolean>('isSubmitting');
export const formIdAtom = hydratedAtom<string>('formId');
export const formLastResultAtom = hydratedAtom<
  ActionResult<unknown> | typeof UNSUBMITTED
>('formLastResult');
export const formSubmissionErrorsAtom = hydratedAtom<ActionError[]>(
  'formSubmissionErrors'
);
export const formUploadsAtom = hydratedAtom<Promise<unknown>[]>('formUploads');
export const formValidationAtom = hydratedAtom<ZodSchema | undefined>(
  'formValidation'
);
export const formCallbacksAtom = hydratedAtom<{
  onValuesChange: ((values: Record<string, unknown>) => void) | undefined;
}>('formCallbacks');

// --- Derived form state ---

export const formValuesAtom = atom((get) => {
  const fieldStates = get(formFieldStatesAtom);
  const fieldValues = fieldStates.map((field) => [field.name, field.value]);
  const values = {};
  fieldValues.forEach(([path, value]) => {
    setProperty(values, path as string, value);
  });
  return values;
});

export const formIsValidAtom = atom((get) => {
  const fieldStates = get(formFieldStatesAtom);
  const hasFieldErrors = fieldStates.some(
    (field) => field.localErrors.length > 0
  );
  if (hasFieldErrors) return false;

  try {
    const values = get(formValuesAtom);
    const validation = get(formValidationAtom);
    if (validation) {
      const result = validation.safeParse(values);
      const hasFormErrors = !result.success;
      if (hasFormErrors) return false;
    }
  } catch (e) {
    return null;
  }

  return true;
});

export const formFieldStatesAtom = atom((get) => {
  const fieldAtoms = get(fieldStateAtomsAtom);
  const fieldStates = Object.values(fieldAtoms).map(get);
  return fieldStates;
});

/**
 * A hook to get the current values of all fields in the form. Use cautiously!
 * This will re-render on every field's value change.
 */
export function useFormValues<T>() {
  return useAtomValue(formValuesAtom) as T;
}

export function useFieldStates() {
  return useAtomValue(formFieldStatesAtom);
}
