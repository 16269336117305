import { atom, Atom, useAtomValue, useSetAtom } from 'jotai';
import { SetStateAction, ChangeEvent, useMemo } from 'react';
import { FieldState, FieldStateAtom } from './create-field-state';

/**
 * The "public API" for a field. This is what you get when you use the
 * `useField` hook. It includes all the FieldState, plus various setters for
 * mutating the field state.
 */
export type FieldApi<T> = FieldState<T> & {
  onValueChange: (value: SetStateAction<T>) => void;
  onChange: (event: ChangeEvent) => void;
  onBlur: () => void;
  reset: () => void;
};

const noopWriteAtom = atom(null, () => {});

/**
 * Takes the set of atoms for a field, and returns the "public API" for the
 * field. Don't use this directly, use the `useField` hook instead.
 *
 * This is needed because the <Field> component can't leverage useField, because
 * it hydrates the atom that useField needs within itself.
 */
export function useFieldApi<T>(stateAtom: FieldStateAtom<T> | Atom<null>) {
  const state = useAtomValue(stateAtom);
  const onValueChange = useSetAtom(
    state?.atoms.onValueChangeAtom ?? noopWriteAtom
  );
  const onChange = useSetAtom(state?.atoms.onChangeAtom ?? noopWriteAtom);
  const onBlur = useSetAtom(state?.atoms.onBlurAtom ?? noopWriteAtom);
  const reset = useSetAtom(state?.atoms.resetAtom ?? noopWriteAtom);

  return useMemo(() => {
    if (!state) {
      return null;
    }
    return {
      ...state,
      onValueChange,
      onChange,
      onBlur,
      reset,
    };
  }, [state, onValueChange, onChange, onBlur, reset]);
}
