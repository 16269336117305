import { ActionError } from '@/lib/action';
import { atom, PrimitiveAtom, SetStateAction, WritableAtom } from 'jotai';
import { ZodSchema } from 'zod';
import { ChangeEvent } from 'react';
import {
  formCallbacksAtom as formCallbacksAtom,
  formSubmissionErrorsAtom,
  formValuesAtom,
} from '../form/atoms';

/**
 * The base state of a field - everything else is derived from this or other
 * base states (i.e. form level errors). This is internal to the form
 * implementation, you shouldn't directly interact with this.
 */
export type FieldBaseState<T> = {
  value: T;
  changed: boolean;
  blurred: boolean;
  validation?: ZodSchema<T>;
};

/**
 * The mutable values and derived state of a field
 */
export type FieldState<T = string> = FieldBaseState<T> & {
  id: string;
  name: string;
  initialValue: T;
  localErrors: ActionError[];
  serverErrors: ActionError[];
  atoms: {
    baseAtom: PrimitiveAtom<FieldBaseState<T>>;
    onValueChangeAtom: WritableAtom<void, [SetStateAction<T>], void>;
    onChangeAtom: WritableAtom<void, [ChangeEvent], void>;
    onBlurAtom: WritableAtom<void, [], void>;
    resetAtom: WritableAtom<void, [], void>;
  };
};

export type FieldStateAtom<T> = WritableAtom<
  FieldState<T>,
  [SetStateAction<FieldBaseState<T>>],
  void
>;

/**
 * Creates the set of atoms to manage a field's state, and specialized writer
 * atoms that form the basis for the field API
 */
export default function createFieldStateAtom<T>(options: {
  id: string;
  name: string;
  initialValue: T;
  validation?: ZodSchema<T>;
  onValueChange?: (value: T) => void;
}) {
  // This atom holds the base state of the field, which are the actual values
  // that can change over time.
  const baseAtom = atom<FieldBaseState<T>>({
    value: options.initialValue as T,
    validation: options.validation,
    changed: false,
    blurred: false,
  });

  // --- Setter atoms ---
  // Jotai atoms with no getter can be used as pure setters. These are like
  // `useAtomCallback`, but don't need to be hooks. We define these here for
  // consistency / co-location with the data they mutate, but they are wrapped
  // in a more ergonomic API via the `useFieldApi()` hook

  const onValueChangeAtom = atom(null, (get, set, value: SetStateAction<T>) => {
    const state = get(stateAtom);
    const newValue: T =
      typeof value === 'function' ? (value as any)(state.value) : value;
    set(stateAtom, {
      ...state,
      value: newValue,
      changed: true,
    });
    options.onValueChange?.(newValue);
    get(formCallbacksAtom)?.onValuesChange?.(get(formValuesAtom));
  });

  const onChangeAtom = atom(null, (_get, set, event: ChangeEvent) => {
    set(onValueChangeAtom, (event.target as HTMLInputElement).value as T);
  });

  const onBlurAtom = atom(null, (_get, set) => {
    set(stateAtom, (state) => ({
      ...state,
      blurred: true,
    }));
  });

  const resetAtom = atom(null, (_get, set) => {
    set(stateAtom, (state) => ({
      ...state,
      value: options.initialValue,
      changed: false,
      blurred: false,
    }));
  });

  // This atom takes the base state atom (the one that actually _mutates_) and
  // wraps it with all the derived read-only state, like form-level errors,
  // derived validation errors, etc.
  const stateAtom = atom(
    (get) => {
      // Get the underlying value
      const baseState = get(baseAtom);

      // Run validations if applicable
      let localErrors: ActionError[] = [];
      if (baseState.validation) {
        const parsed = baseState.validation.safeParse(baseState.value);
        if (parsed.error) {
          localErrors = parsed.error.issues.map((issue) => ({
            path: issue.path.join('.'),
            message: issue.message,
          }));
        }
      }

      // Add in any errors from the server
      const serverErrors: ActionError[] = get(formSubmissionErrorsAtom).filter(
        (error) => error.path === options.name
      );

      // Build the field state
      const state = {
        ...baseState,
        atoms: {
          baseAtom,
          onValueChangeAtom,
          onChangeAtom,
          onBlurAtom,
          resetAtom,
        },
        localErrors,
        serverErrors,
        id: options.id,
        name: options.name,
        initialValue: options.initialValue,
      } as FieldState<T>;

      return state;
    },
    // The setter just updates the base state
    (_get, set, baseState: SetStateAction<FieldBaseState<T>>) => {
      set(baseAtom, baseState);
    }
  );

  return stateAtom;
}
