import { useEffect, useState } from 'react';

/**
 * Returns a boolean indicating whether the component has finished hydration.
 * This is useful for preventing the user from interacting with an element
 * before it has finished loading.
 *
 * Especially when running tests against a development build, hydration can
 * sometimes take a perceptible amount of time. We sometimes want to limit
 * interactions on-page until after hydration completes (i.e. <input> elements
 * will lose any content typed into them when hydration occurs)
 *
 * Note: do *not* use this for anything other than block interactions until
 * hydration completes. This is a pretty generic implementation (change a thing
 * on first render), but we might change the implementation in the future.
 */
export default function useIsHydrated() {
  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    setIsHydrated(true);
  }, []);
  return isHydrated;
}
