'use client';

import { Atom, atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useId, useRef } from 'react';
import { ZodSchema } from 'zod';
import { ScopeProvider } from 'jotai-scope';
import { HydrateAtoms } from '@/lib/atoms';
import { FieldApi, useFieldApi } from './field-api';
import createFieldStateAtom, { FieldStateAtom } from './create-field-state';
import { fieldStateAtomsAtom, formIdAtom } from '../form/atoms';

/**
 * This atom holds a stable reference to the atom that holds the field state.
 *
 * This is necessary because the atom that actually holds the state will be
 * registered with the <Form> - *outside* this Field. Which means it will
 * eventually be useAtom()'d outside our scope provider, and would not return
 * the correct state there.
 *
 * Therefore, we have to create a new atom for each field on-demand, and it
 * *cannot* be captured in a scoped provider local to the field. But at the same
 * time, we want to be able to import the "local" field state anywhere, without
 * having to prop drill it down. Thus, this wrapper basically operates like a
 * Context then, ensuring we have a "bucket" we can import from anywhere that
 * *is* scoped to the local field, but that contains the field state atom which
 * *is not* scoped to the field.
 */
export const localFieldStateAtomAtom = atom<FieldStateAtom<unknown> | Atom<null>>(atom(null));

/**
 * A headless component that provides state management for a field within a
 * form.
 */
export default function Field<T>(props: {
  name: string;
  initialValue: T;
  validation?: ZodSchema<NoInfer<T>>;
  children: React.ReactNode | ((props: {
    field: FieldApi<T>;
  }) => React.ReactNode);
  searchParam?: boolean | {
    name?: string;
    serialize?: (value: T) => string;
    deserialize?: (value: string) => T;
    debounce?: number;
  };
  onValueChange?: (value: T) => void;
}) {
  const id = useId();

  // Provide a helpful early failure in development mode
  if (process.env.NODE_ENV === 'development') {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useAtomValue(formIdAtom);
    } catch (e) {
      throw new Error('You tried to use a <Field> component outside of a <Form>');
    }
  }

  // Pull in the form's registry of field states, to see if this field has been
  // mounted before. If so, we need to reuse the same state atom.
  const [fieldStateAtoms, setFieldStateAtoms] = useAtom(fieldStateAtomsAtom);
  const previouslyMountedFieldStateAtom = fieldStateAtoms[props.name];

  // Create a ref to hold the field state atom. This ensures referential consistency
  // of the field state atom across renders.
  const fieldStateAtomRef = useRef<FieldStateAtom<T>>(previouslyMountedFieldStateAtom as FieldStateAtom<T> ?? createFieldStateAtom({
    id,
    name: props.name,
    initialValue: props.initialValue,
    validation: props.validation,
    onValueChange: props.onValueChange
  }));

  // Register this field with the form on mount/unmount
  useEffect(() => {
    setFieldStateAtoms(prev => ({
      ...prev,
      [props.name]: fieldStateAtomRef.current as FieldStateAtom<unknown>
    }));
    // We don't unregister on purpose - we don't want data to disappear from the
    // form state when you unmount the `<Field>` component (i.e. selecting a
    // different <Tab> in a tabbed form). The setter above overwrites previous
    // sets with the same atom, so it's harmless to run without cleanup
  }, [props.name, setFieldStateAtoms]);
  const api = useFieldApi(fieldStateAtomRef.current!);
  return <ScopeProvider atoms={[localFieldStateAtomAtom]} data-sentry-element="ScopeProvider" data-sentry-component="Field" data-sentry-source-file="field.tsx">
      <HydrateAtoms atoms={[[localFieldStateAtomAtom, fieldStateAtomRef.current]]} data-sentry-element="HydrateAtoms" data-sentry-source-file="field.tsx" />
      {typeof props.children === 'function' ? props.children({
      field: api as FieldApi<T>
    }) : props.children}
    </ScopeProvider>;
}